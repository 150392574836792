//query
(function(){
    window.$ = function(query){ var res = document.querySelectorAll(query); return (res.length == 1)? res[0] : res;}
    Element.prototype.find = function(query){ return this.querySelectorAll(query);};

/* Boucle iterative
    window.each = function(obj, closure){
        for(let i=0; i< obj.length; i++) closure(obj[i]);
    }
    HTMLCollection.prototype.each = NodeList.prototype.each = Object.prototype.each = Array.prototype.each = function(closure){for(i=0; i< this.length; i++) closure(this[i]);};
    Element.prototype.each = function(closure){closure(this);};
//*/
//* manipulation de class et style
    const addClass = function(className){for(i=0; i< this.length; i++) this[i].classList.add(className); return this;}
    const removeClass = function(className){for(i=0; i< this.length; i++) this[i].classList.remove(className); return this;}
    const toggleClass = function(className) {
        for (i = 0; i < this.length; i++){
            if (this[i].hasClass(className))
                this[i].removeClass(className);
            else
                this[i].addClass(className);
        }
        return this;
    }

    HTMLCollection.prototype.addClass = NodeList.prototype.addClass = addClass;
    HTMLCollection.prototype.removeClass = NodeList.prototype.removeClass = removeClass;
    HTMLCollection.prototype.toggleClass = NodeList.prototype.toggleClass = toggleClass;

    Element.prototype.addClass      = Node.prototype.addClass      = function(className){
        tab = className.split(' ');
        for(i=0; i< tab.length; i++) this.classList.add(tab[i]);
        return this;
    }
    Element.prototype.removeClass   = Node.prototype.removeClass   = function(className){this.classList.remove(className); return this;}
    Element.prototype.hasClass      = Node.prototype.hasClass      = function(className){return this.classList.contains(className);}
    Element.prototype.toggleClass   = Node.prototype.toggleClass   = function(className){
        if(this.hasClass(className))
            this.removeClass(className);
        else
            this.addClass(className);
        return this;
    }

    Element.prototype.setStyle = function($style, value){this.style[$style] = value; return this;}
//*/
//* attribute
    const addAttr = function(key, value){ this.setAttribute(key, value); return this;}
    const removeAttr = function(key){ this.removeAttribute(key); return this;}
    const getAttr = function(key){ return this.getAttribute(key);}
    Element.prototype.addAttr = addAttr;
    Element.prototype.removeAttr = removeAttr;
    Element.prototype.getAttr = getAttr;
//*/
//* event
    const addEvent = function(type, closure){this.addEventListener(type,closure);}
    const addEvents = function(type, closure){for(i=0; i< this.length; i++) this[i].addEventListener(type,closure);}
    HTMLCollection.prototype.addEvent = NodeList.prototype.addEvent = addEvents;
    Element.prototype.addEvent = Node.prototype.addEvent = addEvent;
//*/
//* data
    Element.prototype.setData = function(key, value){ this.dataset[key] = value; return this;}
//*/
//* text
    Element.prototype.txt = function(txt){ this.innerText = txt; return this;}
    Element.prototype.html = function(html){ this.innerHTML = html; return this;}
//*/
//* creation element
    window.createElement = function(type){
        return document.createElement(type);
    }
    window.createElementNS = function(namespace, type){
        return document.createElementNS(namespace, type);
    }
    Element.prototype.createElementInside = function(type){
        var el = createElement(type);
        this.append(el);
        return el;
    }
    Element.prototype.createElementAfter = function(type){
        var el = createElement(type);
        this.parentElement.insertBefore(el, this.nextSibling);
        return el;
    }

    Element.prototype.createElementInsideNS = function(namespace, type){
        var el = createElementNS(namespace, type);
        this.append(el);
        return el;
    }
    Element.prototype.destroy = function(){
        this.remove();
    }
    HTMLCollection.prototype.destroy = NodeList.prototype.destroy = function(){for(i=0; i< this.length; i++) this[i].remove();}
//*/
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*/offset

    Element.prototype.offset = function offset() {
        var rect = this.getBoundingClientRect(),

            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft , width: rect.width, height: rect.height}
    }
//*/


//* Autocompletion

    window.Autocompletion = function(target, type='list'){
        var $this = this;
        this.autocomplete = document.createElement('div');
        this.autocomplete.addClass('form-autocomplete').addClass('d-none').setStyle('zIndex', 1000);
        if(type == 'list') this.list = this.autocomplete.createElementInside('ul');
        if(type == 'content') this.content = this.autocomplete.createElementInside('div');
        this.autocomplete.setStyle('width',target.offsetWidth + 'px').setStyle('left', target.offsetLeft + 'px').setStyle('top', (target.offsetTop + target.offsetHeight) + 'px');
        target.parentNode.insertBefore(this.autocomplete, target.nextSibling);

        window.addEventListener('click', function(){ $this.autocomplete.addClass('d-none');})
    }
    Autocompletion.prototype.addList = function(data){
        this.list.innerHTML = '';
        for(let i = 0; i < data.length; i++){
            var li = this.list.createElementInside('li');
            li.innerText = data[i];
            li.addEvent('click', function(e){
                this.hide();
                this.target.value = data[i];
            });
        }
        return this;
    }

    Autocompletion.prototype.addHTML = function(content){
        this.content.innerHTML = '';
        if (typeof content == 'string') this.content.innerHTML = content;
        else this.content.append(content);
        return this;
    }

    Autocompletion.prototype.addContent = function(closure){ closure(this); return this; }
    Autocompletion.prototype.hide = function(){ this.autocomplete.addClass('d-none'); return this; }
    Autocompletion.prototype.show = function(){ this.autocomplete.removeClass('d-none'); return this; }
//*/
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//* eval script
    Element.prototype.evalScript = function(){
        var scriptElements = this.getElementsByTagName('script');
        for (let i = 0; i < scriptElements.length; i ++) {
            eval(scriptElements[i].innerHTML);
        }
    }
//*/
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//* array
    Array.prototype.keyBy = function(key){
        var tp = {};
        for (let i=0; i<this.length; i++) {
            tp[this[i][key]] = this[i];
        }
        return tp;
    }
//*/
})();
