(function () {

    Element.prototype.range = function (options = {}) {

        // initialisation
        var regle, jauge, cursor1, cursor2, posMin, posMax;
        const $this = this;
        const name = (this.dataset.name) ? this.dataset.name : 'range';
        const hiddenField = document.createElement('input').addAttr('type', 'hidden').addAttr('name', name);
        hiddenField.value = (this.dataset.value) ? this.dataset.value : null;
        $this.append(hiddenField);

        //data specifique

        const min = (this.dataset.min) ? parseInt(this.dataset.min) : 0;
        const max = (this.dataset.max) ? parseInt(this.dataset.max) : 0;
        const step = (this.dataset.step) ? parseInt(this.dataset.step) : 0;

        const updateValue = function(){
            if(!cursor2){
                hiddenField.value = cursor1.getValue();
            }else{
                hiddenField.value = [cursor1.getValue(), cursor2.getValue()];
            }
        }

        const cursorFactory = function(parent, callback = function(a,b){}, type= null, previous = null){

            //--------------------------
            const cursor = function(){
                //init
                var cursorX, firstX, halfCursor;
                const $this = this;

                // dessine le curseur
                this.curs = parent.createElementInside('div').addClass('range-cursor').addAttr('draggable', 'true');
                //this.curs.setStyle('top', parent.offsetHeight + 'px');
                this.curs.setStyle('top', -(this.curs.offsetHeight - (parent.offsetHeight + parseInt(getComputedStyle(parent)['border-top-width']) + parseInt(getComputedStyle(parent)['border-bottom-width']))/2)/2 + 'px');
                this.curs.createElementInsideNS('http://www.w3.org/2000/svg', 'svg').addAttr('width', '12').addAttr('height', '12')
                    .createElementInsideNS('http://www.w3.org/2000/svg', 'g')
                    .createElementInsideNS('http://www.w3.org/2000/svg', 'circle')
                    .addAttr('cx', 6).addAttr('cy', 6).addAttr('r', 6).addClass("cursor");

                this.val = this.curs.createElementInside('div').addClass('range-valeur').setStyle('top', (this.curs.offsetHeight) + 'px');
                //
                halfCursor = this.curs.offsetWidth / 2;
                //
                this.init = function (value, right= false, def = min) {
                    let val = value != "" ? parseInt(value) : def;
                    $this.setValue(val);
                    let r = parent.offsetWidth / (max - min);
                    let left = ((((val - min) * r)) - (right ? $this.curs.offsetWidth : 0));
                    $this.curs.style.left = left + 'px';
                    callback(left, $this.curs.offsetWidth);
                }
                //
                this.drag = function(e){
                    let border = parseInt(getComputedStyle(parent)['border-top-width']) + parseInt(getComputedStyle(parent)['border-bottom-width']);
                    console.log(border);
                    let posx = cursorX + e.pageX - firstX;
                    posx = (posx > parent.offsetLeft + parent.offsetWidth - $this.curs.offsetWidth) ? parent.offsetLeft + parent.offsetWidth -  $this.curs.offsetWidth : posx;
                    posx = (posx <  0) ?  0 : posx;
                    let ratio = (posx / (parent.offsetWidth -  $this.curs.offsetWidth));
                    $this.setValue(Math.round(min + (max - min) * ratio));
                    $this.curs.style.left = posx + 'px';
                    callback(posx, $this.curs.offsetWidth + border);
                }
                //
                this.curs.addEventListener('mousedown', function(e){
                    this.addClass('focus');
                    e.preventDefault();
                    cursorX = this.offsetLeft;
                    firstX = e.pageX;
                    parent.parentElement.addEventListener('mousemove', $this.drag, false);
                    window.addEventListener('mouseup', function() {
                        $this.curs.removeClass('focus');
                        updateValue();
                        parent.parentElement.removeEventListener('mousemove', $this.drag, false);
                        }, false);
                });
                window.addEventListener('resize', function(){$this.init($this.val.innerText)});
            }

            cursor.prototype.setValue = function(value){ this.val.innerText = value; }
            cursor.prototype.getValue = function(){ return this.val.innerText; }
            cursor.prototype.setPos = function(pos){ this.curs.style.left = pos; }
            return new cursor();
        }

        const build = function(){
            $this.addClass('input-range');
            regle = $this.createElementInside('div').addClass('range-regle');
            let legendMin = $this.createElementInside('div').addClass('range-legend-min');
            let legendMax = $this.createElementInside('div').addClass('range-legend-max');
            legendMin.innerText = min;
            legendMax.innerText = max;
            jauge = regle.createElementInside('div').addClass('range-jauge');
            //
            let val = hiddenField.value.split(',');
            cursor1 = cursorFactory(regle, function(pos, delta){jauge.style.left = pos + 'px';}, 'double');
            cursor1.init(val[0]);
            cursor2 = cursorFactory(regle,function(pos, delta){jauge.style.right = regle.offsetWidth - (pos + delta) + 'px';}, 'double', cursor1);
            cursor2.init(0, true, val[1] ? val[1] : max);
            //
            hiddenField.value = hiddenField.value ? hiddenField.value : min + ',' + max;
        }
        build();
    }
})();
