///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Element.prototype.accordeon = function (options = {}){
    const $this = this;
    const handleClass = options.handle ? options.handle : "accordeon-handle";
        this.handles = this.querySelectorAll(':scope > .' + handleClass);
    this.sections = [];
    //
    const fix = function(section){
        if(section.hasClass('open-fix')){
            section.removeClass('open-fix');
            section.setStyle('height', document.getElementById(section.dataset.target).offsetHeight + 'px');
        }
    }
    //
    const colapseSections = function(){
        $this.sections.forEach(function(el){fix(el); el.setStyle('height', 0);});
        $this.handles.forEach(function(el){el.removeClass('open')});
    }
    //
    $this.handles.forEach(function(item){
        const content = document.getElementById(item.dataset.target);
        const section = document.createElement('div');
        section.setAttribute('data-target', item.dataset.target);
        section.addClass('accordeon-section');
        if(!item.hasClass('open')) section.setStyle('height', 0);
        content.parentNode.insertBefore(section, content);
        section.append(content);
        $this.sections.push(section);
        //
        item.addEventListener('click', function(e){
            e.preventDefault();
            let close = !this.hasClass('open');
            fix(section);
            if(!$this.dataset.accordeonFree) colapseSections();
            section.setStyle('height', 0);
            item.removeClass('open');
            if(close){
                this.addClass('open');
                section.setStyle('height', document.getElementById(this.dataset.target).offsetHeight + 'px');
            }
        });
        //
        section.addEventListener('transitionend', function(e){
            if(parseInt(this.style.height) > 0) this.addClass('open-fix');
        });
    });
}

